<template>
    <div>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">
          <!-- ITEMS PER PAGE -->
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2"
                  >{{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    pendingPayments.length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : pendingPayments.length
                  }}
                  de {{ pendingPayments.length }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>

              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
            <p style="margin-top:-60px; margin-right:-40px; font-size:10px"> Desde</p>
            <date-picker :language="languages.es" v-model="from" :first-day-of-week="1" label="Desde" placeholder="Desde" style="margin-right:-10px"></date-picker>
            <p style="margin-top: -60px; margin-right:-40px; font-size:10px"> Hasta</p>
            <date-picker :language="languages.es" v-model="to" type="datetime" label="Hasta" placeholder="Hasta" style="margin-right:-10px"></date-picker>
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              placeholder="Buscar..."/>
            <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()"
              >Exportar</vs-button>
          </div>

        </div>
        <ag-grid-vue
          ref="agGridTable"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="pendingPayments"
          @first-data-rendered="onFirstDataRendered"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
          :modules="modules">
        </ag-grid-vue>
        <ag-grid-vue style="height: 60px; flex: none;"
          class="ag-theme-material w-100 ag-grid-table total"
          :gridOptions="bottomGridOptions"
          :headerHeight="0"
          :columnDefs="columnDefs"
          :floatingFiltersHeight="0"
          :rowData="summaryFooter"
          :modules="modules">
        </ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
        <vs-popup fullscreen title="Recibo" :active.sync="popupActive">
          <iframe v-if="isPdf" :src="receiptUrl+'#toolbar=0'" frameborder="0" style="width:100%; height:100%;"></iframe>
          <vs-row v-else vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12">
              <img :src="receiptUrl" />
          </vs-row>
        </vs-popup>

        <popup-detail
          :data="popupData"
          :title="popupTitle"
          :popupActive="popupActiveDetails"
          @updatePopupActive="updatePopupActive(false)"
        ></popup-detail>
    </div>
</template>
<script>
import * as lang from 'vuejs-datepicker/src/locale';
import DatePicker from "vuejs-datepicker";
import { AgGridVue } from "@ag-grid-community/vue";
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import CellRendererActions from "./components/CellRendererActionsProcessedRequests.vue";
// Store Modules
import moduleReport from "@/store/report/moduleReport.js";
import PopupDetail from "./components/PaymentRequestDetails.vue"

export default {
    components: {
        DatePicker,
        AgGridVue,
        CellRendererActions,
        PopupDetail
    },
    data() {
        return {
            languages: lang,
            from: new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()),
            to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            modules: AllCommunityModules,
            pendingPayments: [],

            //Sidebar
            paymentsSidebar: false,
            paymentsSidebarActive: false,
            sidebarData: {},

            // Popup Details
            popupData: [],
            popupTitle: '',
            popupActiveDetails: false,

            //MockData
            mockData: [
                {
                    "id": "1",
                    "school_name": "School Test",
                    "school_admin": "Admin School 123",
                    "period": "November 2023",
                    "request_date": "15/11/2023",
                    "recurring_amount_before_tax": 5000,
                    "payment_processing_fee_percentage": 1,
                    "payment_processing_fee_amount": 50,
                    "tax": 7,
                    "tax_amount": 3.50,
                    "recurring_amount_after_tax": 4946.50,
                    "total_pay": 4946.50
                }
            ],

            //Table
            searchQuery: "",
            gridOptions: {
              alignedGrids: [],
              suppressHorizontalScroll: true,
              context: {}
            },
            maxPageNumbers: 7,
            gridApi: null,
            defaultColDef: {
                sortable: true,
                editable: false,
                resizable: true,
                suppressMenu: true,
            },

            groupHeaderHeight: 150,
            headerHeight: 100,

            columnDefs: [
                {
                    headerName: "Ticket",
                    field: "id",
                    width: 60,
                    filter: true,
                },
                {
                    headerName: "Escuela",
                    field: "school_name",
                    width: 160,
                    filter: true
                },
                {
                    headerName: "Total Pagos Recibidos",
                    field: "total_before_tax",
                    width: 120,
                    filter: true,
                    valueFormatter: params => this.$options.filters.currencySign(params.data.total_before_tax, '$ '),
                },
                {
                    headerName: "Comisión",
                    field: "tiptap_commission_amount",
                    width: 100,
                    filter: true,
                    valueFormatter: params => `${this.$options.filters.currencySign(params.data.tiptap_commission_amount, '$ ')} ${params.data.tiptap_commission_percent != undefined ? '(' + this.$options.filters.currencyPercentage(params.data.tiptap_commission_percent, ' %') + ')' : ''}`,
                    // cellStyle: {color: 'blue', fontWeight: '600'}
                },
                {
                    headerName: "ITBMS",
                    field: "national_tax_amount",
                    width: 100,
                    filter: true,
                    valueFormatter: params => `${this.$options.filters.currencySign(params.data.national_tax_amount, '$ ')} ${params.data.national_tax_percent != undefined ? '(' + this.$options.filters.currencyPercentage(params.data.national_tax_percent, ' %') + ')' : ''}`
                },
                {
                    headerName: "Total Cobrado",
                    field: "total_after_tax",
                    width: 100,
                    filter: true,
                    valueFormatter: params => this.$options.filters.currencySign(params.data.total_after_tax, '$ '),
                },
                {
                    headerName: "Fecha de Solicitud",
                    field: "request_date",
                    width: 140,
                    filter: true,
                    comparator: this.myDateComparator
                },
                {
                    headerName: "Fecha de Pago",
                    field: "pay_date",
                    width: 140,
                    filter: true,
                    comparator: this.myDateComparator
                },
                {
                    headerName: "Acción",
                    filter: true,
                    width: 80,
                    cellRendererFramework: 'CellRendererActions',
                    cellRendererParams: {
                        // openSideBar: this.openSideBar.bind(this),
                        showPopupReceipt: this.showPopupReceipt.bind(this),
                        showPopupDetails: this.showPopupDetails.bind(this),
                    }
                },
            ],

            // Popup Receipt
            popupActive: false,
            isPdf: false,
            receiptUrl: '',

            // Bottom Summary Row
            bottomGridOptions: {
              alignedGrids: [],
              defaultColDef: {
                  editable: false,
                  sortable: true,
                  resizable: true,
                  filter: true,
                  flex: 1,
                  floatingFiltersHeight: 0
              }
            },

            summaryFooter: [],
        }
    },
    created() {
        this.loadPendingPaymentsTables()
    },
    methods: {
        showPopupReceipt(isPdf, receipt) {
          this.isPdf = isPdf
          this.receiptUrl = receipt
          this.popupActive = true
        },
        isDate(val) {
            return val instanceof Date && !isNaN(val.valueOf())
        },
        loadPendingPaymentsTables() {
          this.pendingPayments = [];
          this.summaryFooter = [];

          this.$store.dispatch("reportModule/getDirectPaymentCashOutRequests", this.getQueryParams())
              .then(response => {
                  if (response.data.requests.length > 0) {
                      let s = JSON.parse(JSON.stringify(response.data.requests));
                      let s_footer = {};
                      s_footer.id = "Total";
                      s_footer.total_before_tax = 0;
                      s_footer.tiptap_commission_amount = 0;
                      s_footer.national_tax_amount = 0;
                      s_footer.total_after_tax = 0;

                      s.forEach((element) => {
                            element.request_date = element.request_date ? new Date(element.request_date).toLocaleDateString("es-AR", this.$localeDateOptions) : element.request_date;
                            element.pay_date = element.pay_date ? new Date(element.pay_date).toLocaleDateString("es-AR", this.$localeDateOptions) : element.pay_date;
                            element.school_name = this.$store.getters['dataListInstitutions/getSchoolById'](element.id_commerce).name

                            s_footer.total_before_tax += element.total_before_tax;
                            s_footer.tiptap_commission_amount += element.tiptap_commission_amount;
                            s_footer.national_tax_amount += element.national_tax_amount;
                            s_footer.total_after_tax += element.total_after_tax;
                        }),

                      this.summaryFooter = [s_footer];
                      this.pendingPayments = s;
                  }
              })
              .catch(err => console.log(err))
        },
        getQueryParams() {
          const dates = {from: this.isDate(this.from)?this.from.toISOString().split('T')[0] : '', to:this.isDate(this.to)?this.to.toISOString().split('T')[0] : ''};

          return `?status=1&from=${dates.from}&to=${dates.to}`
        },
        toggleDataSidebar(val=false) {
            this.paymentsSidebarActive = val
        },
        openSideBar() {
            this.toggleDataSidebar(true);
        },
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val);
        },
        myDateComparator(date1, date2) {
            var date1Number = this.monthToComparableNumber(date1);
            var date2Number = this.monthToComparableNumber(date2);
            if (date1Number === null && date2Number === null) {
                return 0;
            }
            if (date1Number === null) {
                return -1;
            }
            if (date2Number === null) {
                return 1;
            }
            return date1Number - date2Number;
        },
        monthToComparableNumber(date) {
            if (date === undefined || date === null || date.length !== 10) {
                return null;
            }
            var yearNumber = date.substring(6, 10);
            var monthNumber = date.substring(3, 5);
            var dayNumber = date.substring(0, 2);
            var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
            return result;
        },
        resolvePeriod(period) {
            if(!period) return
            const p = new Date(period)
            return p.toLocaleString('default', { month: 'long' }) + ' ' + p.getFullYear()
        },
        showPopupDetails(requestData) {
          this.getRequestDetail(requestData.id)
        },
        updatePopupActive(val) {
          this.popupActiveDetails = val
        },
        getRequestDetail(id) {
          if (!id) return

          this.$store.dispatch("reportModule/getDirectPaymentCashOutRequestDetail", id)
          .then(response => {
            if (!response.status) return

            this.popupData = response.data.detail
            this.popupTitle = `Pago Directo - Detalle de solicitud #${id}`
            this.popupActiveDetails = true
          })
          .catch(error => console.log(error))
        },
        resolveStatus(val) {
          if(val && val != "")
            return `Pagado el ${val}`

          return ''
        },
        onFirstDataRendered: function() {
          this.gridColumnApi.autoSizeAllColumns();
        },
    },
    watch: {
      paymentsSidebarActive: function () {
        this.toggleDataSidebar(this.paymentsSidebarActive)
      },
      "$store.state.windowWidth"(val) {
        if (val <= 576) {
            this.maxPageNumbers = 7;
        }
      },
      from: function () {
        this.loadPendingPaymentsTables();
      },
      to: function () {
        this.loadPendingPaymentsTables();
      },
    },
    computed: {
        paginationPageSize() {
        if (this.gridApi) return this.gridApi.paginationGetPageSize();
        else return 50;
        },
        totalPages() {
        if (this.gridApi) return this.gridApi.paginationGetTotalPages();
        else return 0;
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1;
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1);
            },
        },
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;

        if (this.$vs.rtl) {
        const header = this.$refs.agGridTable.$el.querySelector(
            ".ag-header-container"
        );
        header.style.left =
            "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
        }
    },
    beforeMount() {
      this.gridOptions.alignedGrids.push(this.bottomGridOptions);
      this.bottomGridOptions.alignedGrids.push(this.gridOptions);

      this.gridOptions.context = {
        componentParent: this
      };
    },
    beforeCreate() {
        if(!moduleReport.isRegistered) {
            this.$store.registerModule('reportModule', moduleReport)
            moduleReport.isRegistered = true
        }
    },
}
</script>
<style>
  .ag-header-cell-label .ag-header-cell-text{
    overflow: visible;
    text-overflow: clip;
    white-space: normal;
  }
  .ag-grid-table.total .ag-row {
    font-weight: 700;
  }
  .ag-grid-table.total .ag-header {
    border: none;
  }
</style>
