<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}" v-if="this.params.data.national_tax_percent != undefined">
    <vx-tooltip class="float-left" text="Ver Detalle">
      <feather-icon icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer stroke-current" class="ml-2" @click="clickHandler()" />
    </vx-tooltip>
    <div v-if="this.params.data.receipt">
      <vx-tooltip class="float-left" text="Recibo">
        <feather-icon icon="FileTextIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="callShowPopup" />
      </vx-tooltip>
    </div>
  </div>
</template>

<script>
  export default {
      name: 'CellRendererActionsDirectPaymentProcessedRequests',
      data() {
          return {
              popupActive: false,
              isPdf: false
          }
      },
      methods: {
          setFileType() {
              if(this.params.data.receipt) {
                  let {length, [length-1]: ext} = this.params.data.receipt.split('.')
                  this.isPdf = (ext === 'pdf');
              }
          },
          callShowPopup() {
              this.params.showPopupReceipt(this.isPdf, this.$baseUrlImg + this.params.data.receipt)
          },
          clickHandler() {
            this.params.showPopupDetails(this.params.data)
          }
      },
      mounted() {
          this.setFileType()
      }
  }
</script>
<style>
  .vs-popup--content {
      height:100% !important;
  }
</style>
